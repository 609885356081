import * as React from 'react';
import { graphql } from 'gatsby';
//components
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { GatsbyImage } from 'gatsby-plugin-image';
import { colors, device } from '../components/GlobalStyles';
import styled from '@emotion/styled';
import { Seo } from '../components/Seo';
//layout
import { DefaultLayout } from '../layouts/DefaultLayout';

const Section = styled.section`
    .ok {
        max-width: 350px;
        height: auto;
    }
    a {
        font-size: 21px;
        color: ${colors.green};
        p & {
            color: ${colors.green};
            font-weight: bold;
        }
        &:hover {
            text-decoration: underline;
        }
    }
    p {
        font-size: 19px;
    }
    margin-top: 3em;
    padding: 2em 1em;
    ul {
        margin-bottom: 1em;
    }
    button {
        cursor: pointer;
    }

    h1 {
        line-height: 58px;
        font-size: 52px;
        text-transform: capitalize;
        font-weight: 700;
        margin-bottom: 48px;
    }
    h2 {
        margin-top: 1.05em;
        font-size: 32px;
    }
    h4 {
        display: none;
    }

    h2,
    p {
        margin-bottom: 10px;
    }
    .yellow-box {
        display: none;
        span {
            font-size: 24px;
            font-weight: 700;
        }
        button {
            background: ${colors.grey};
            color: ${colors.white};
            border: none;
            margin-left: 40px;
            padding: 1em;
        }
        p {
            margin: 2em 0em 1.5em 0em;
            line-height: 24px;
            font-size: 14px;
        }
        margin: 0em 3em;
        padding: 1em;
        width: 271px;
        background: ${colors.yellowGradient};
    }
    .flex {
        margin-top: 159px;
        display: flex;
        justify-content: space-around;
        .sidepanel-list {
            list-style: none;
            display: none;
            li {
                font-weight: 700;
                margin: 2em 0em;
                &:hover {
                    color: ${colors.green};
                }
            }
        }
    }
    .contain {
        background: white;
        width: 983px;
    }

    padding-top: 10px;
    .image {
        padding: 1em;
        margin-bottom: 20px;
    }
    .markdown {
        padding: 0px 3.8vw;
        margin-top: -3em;
    }

    h3 {
        font-size: 28px;
        margin-top: 1em;
        margin-bottom: 0.5em;
        text-transform: capitalize;
        color: ${colors.green};
    }
    @media ${device.tablet} {
        padding: 2em 3em;
        h1 {
            margin-bottom: 24px;
        }
    }
    @media ${device.laptop} {
        padding: 2em 4em;
        article {
            margin-bottom: 4em;
        }
        h1 {
            margin-bottom: 34px;
        }
        .content {
            max-width: 1440px;
            margin: 24px auto;
        }
        margin: 2em auto;
        .image {
            margin: 0 auto;
            display: flex;
            justify-content: center;
        }
    }
    @media ${device.laptopL} {
        .sidepanel-list,
        .yellow-box,
        h4 {
            display: block;
        }
        h4 {
            margin-left: 3.8em;
            margin-bottom: 4em;
        }
    }
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Resources: Page<any> = ({ data }) => {
    const { meta } = data.mdx.frontmatter;
    return (
        <>
            <Seo {...meta} />
            <Section>
                <h4>ABOUT US</h4>
                <div className="image">
                    <GatsbyImage
                        className="ok"
                        alt="frontal picture of doctor"
                        image={data.mdx.frontmatter.featuredImage.childImageSharp.gatsbyImageData}
                    />
                </div>
                <div className="content">
                    <h1>{data.mdx.frontmatter.title}</h1>
                    <MDXRenderer>{data.mdx.body}</MDXRenderer>
                </div>
            </Section>
        </>
    );
};

export const query = graphql`
    query ($id: String!) {
        mdx(id: { eq: $id }) {
            frontmatter {
                title
                slug
                name
                categories
                meta {
                    title
                    description
                }
                featuredImage {
                    name
                    childImageSharp {
                        gatsbyImageData(
                            width: 400
                            placeholder: BLURRED
                            quality: 90
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }
            }
            body
            id
        }
        allMdx(filter: { fields: { sourceInstanceName: { eq: "sources" } } }) {
            nodes {
                frontmatter {
                    slug
                    title
                    categories
                }
                body
                excerpt
                slug
                id
            }
        }
    }
`;

export default Resources;

Resources.Layout = DefaultLayout;
